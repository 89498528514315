import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../config/axios';
import notification from '../components/notification';
import { cmsOrgClientActions } from './index';
import parseFormData from '../utils/parseFormData';

export const postOrgClient = createAsyncThunk(
  'orgClient/postOrgClient',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/admin/orgClient', params.formData);
      dispatch(
        cmsOrgClientActions.addOneOrgClientToList(response.data.orgClient)
      );
      params.resetForm();
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed to post client');
    }
  }
);

export const getAllOrgClient = createAsyncThunk(
  'orgClient/getAllOrgClient',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get('/admin/orgClient');
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed to load all client');
    }
  }
);

export const getOneOrgClient = createAsyncThunk(
  'orgClient/getOneOrgClient',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/faq/get/${params.faqclientNameId}`
      );
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed to load faq clientName!');
    }
  }
);
export const putOrgClient = createAsyncThunk(
  'orgClient/putOrgClient',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put('/admin/orgClient', params.formData);
      const formData = parseFormData(params.formData);
      dispatch(cmsOrgClientActions.updateOrgClient(formData));
      params.resetForm();
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed to update client.');
    }
  }
);
export const deleteOrgClient = createAsyncThunk(
  'orgClient/deleteOrgClient',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`/admin/orgClient/${params._id}`);
      dispatch(cmsOrgClientActions.deleteOrgClient(response.data.orgClient));
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed to delete client!');
    }
  }
);

export const changeIsPublishStatus = createAsyncThunk(
  'orgClient/changeIsPublishStatus',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `/admin/orgClient/changeIsPublish/${params._id}`
      );
      dispatch(cmsOrgClientActions.changeIsPublishStatus({ _id: params._id }));
      params.enableSwitch();
      return response.data.orgClient;
    } catch (err) {
      return rejectWithValue('Failed!');
    }
  }
);
export const searchOrgClient = createAsyncThunk(
  'orgClient/searchOrgClient',
  async (params, { rejectWithValue }) => {
    try {
      const response = axios.get(
        `/admin/orgClient/search/:${params.searchKey}`
      );
      return (await response).data.faq;
    } catch (err) {
      return rejectWithValue('Not items found!');
    }
  }
);

const orgClientSlice = createSlice({
  name: 'orgClient',
  initialState: {
    list: [],
    editItem: null,
  },
  reducers: {
    addOneOrgClientToList(state, action) {
      state.list = [action.payload, ...state.list];
    },
    deleteOrgClient(state, action) {
      !state.list.length
        ? (() => {})()
        : (() => {
            const objectIndex = current(state).list.findIndex(
              (item) => item._id === action.payload._id
            );
            objectIndex < 0 ? (() => {})() : state.list.splice(objectIndex, 1);
          })();
    },
    editItem(state, action) {
      state.editItem = action.payload;
    },
    initializeEditItem(state, action) {
      state.editItem = null;
    },
    updateOrgClient(state, action) {
      const objectIndex = state.list.findIndex(
        (item) => item._id === action.payload._id
      );
      objectIndex < 0
        ? (() => {})()
        : (() => {
            state.list[objectIndex].clientName = action.payload.clientName;
            state.list[objectIndex].isPublish = action.payload.isPublish;
            state.list[objectIndex].displayOrder = action.payload.displayOrder;
          })();
    },
    changeIsPublishStatus(state, action) {
      const objectIndex = state.list.findIndex(
        (item) => item._id === action.payload._id
      );
      objectIndex < 0
        ? (() => {})()
        : (() => {
            state.list[objectIndex].isPublish =
              !state.list[objectIndex].isPublish;
          })();
    },
  },

  extraReducers: {
    [postOrgClient.pending]: (state, action) => {
      // notification.close();
      notification('info', { message: 'Working on it!' });
    },

    [postOrgClient.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Success.',
        description: 'Client saved successfully.',
      });
    },

    [postOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [putOrgClient.pending]: (state, action) => {
      notification('info', { message: 'Working on it!' });
    },

    [putOrgClient.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Success.',
        description: 'Client Updated successfully.',
      });
    },

    [putOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [deleteOrgClient.pending]: (state, action) => {
      notification('info', { message: 'Working on it!' });
    },

    [deleteOrgClient.fulfilled]: (state, action) => {
      notification('success', {
        message: 'Success.',
        description: 'Client Deleted successfully.',
      });
    },

    [deleteOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [changeIsPublishStatus.pending]: (state, action) => {
      // notification("info", { message: "Working on it!" });
    },

    [changeIsPublishStatus.fulfilled]: (state, action) => {
      // state.list.push(action.payload);
      notification('success', {
        message: 'Success.',
        description: 'Updated successfully.',
      });
    },

    [changeIsPublishStatus.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [getAllOrgClient.pending]: (state, action) => {
      // notification("info", { message: "Working on it!" });
    },

    [getAllOrgClient.fulfilled]: (state, { payload }) => {
      state.list = payload;
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.',
      });
    },

    [getAllOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [getOneOrgClient.pending]: (state, action) => {
      // notification("info", { message: "Working on it!" });
    },

    [getOneOrgClient.fulfilled]: (state, action) => {
      state.list.push(action.payload);
      notification('success', {
        message: 'Success.',
        description: 'Fetched successfully.',
      });
    },

    [getOneOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'Server error or validation error! ',
      });
    },

    [searchOrgClient.pending]: (state, action) => {
      // notification("info", { message: "Working on it!" });
    },

    [searchOrgClient.fulfilled]: (state, action) => {
      state.list.push(action.payload);
      // notification("success", { message: "Success.", description: "" });
    },

    [searchOrgClient.rejected]: () => {
      notification('error', {
        message: 'Error!',
        description: 'No match found! ',
      });
    },
  },
});
export default orgClientSlice;
