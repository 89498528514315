import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DashboardOutlined } from '@ant-design/icons';
import {
  RiAdvertisementLine,
  RiPagesLine,
  RiGalleryLine,
  RiTeamLine,
} from 'react-icons/ri';
import { GiKnightBanner } from 'react-icons/gi';
import { FcFaq } from 'react-icons/fc';
import {
  MdReviews,
  MdPermContactCalendar,
  MdOutlineUnsubscribe,
} from 'react-icons/md';
import { BsFilePost } from 'react-icons/bs';
import {
  AiOutlineProject,
  AiTwotoneCustomerService,
  AiTwotonePieChart,
  AiOutlineBell,
} from 'react-icons/ai';
import { MdPermMedia } from 'react-icons/md';
import { FcSettings } from 'react-icons/fc';
import { HiUserGroup } from 'react-icons/hi';

import { SiAboutdotme } from 'react-icons/si';
import { BiUserCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import AdminRoutes from '../../routes/cmsRoutes/CmsRoutes';
import PresentWorkingPanel from '../../components/PresentWorkingPanel';
import firstLetterCaps from '../../utils/firstLetterCaps';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, icon, children) {
  return {
    key: uuidv4(),
    icon,
    children,
    label,
  };
}
const items = [
  // getItem(
  //   <Link to="">Dashboard</Link>,
  //   <Link to="">
  //     <AiTwotonePieChart size={25} />
  //   </Link>
  // ),

  // getItem("Advertisement", <RiAdvertisementLine />, [
  //   getItem(
  //     <Link to="/viewAdvertisement">
  //       {firstLetterCaps("View advertisement")}
  //     </Link>
  //   ),
  //   getItem(
  //     <Link to="/addAdvertisement">{firstLetterCaps("Add advertisement")}</Link>
  //   ),
  // ]),
  // getItem('Gallery', <RiGalleryLine />, [
  //   getItem(
  //     <Link to="/galleryManagement">{firstLetterCaps('Add gallery')}</Link>
  //   ),
  //   getItem('Gallery'),
  // ]),

  // getItem('Banner Management', <GiKnightBanner size={25} />, [
  //   getItem(<Link to="/viewBanner">{firstLetterCaps('View Banners')}</Link>),
  //   getItem(<Link to="/addBanner">{firstLetterCaps('Add Banner')}</Link>),
  // ]),

  // getItem('Page', <RiPagesLine />, [
  //   getItem(firstLetterCaps('View pages')),
  //   getItem(<Link to="/siteSetting">{firstLetterCaps('Add page')}</Link>),
  // ]),

  // getItem('FAQ', <FcFaq />, [
  //   getItem(<Link to="/viewFaq">{firstLetterCaps('View FAQ')}</Link>),
  //   getItem(
  //     <Link to="/faqCategoryPanel">{firstLetterCaps('FAQ Category')}</Link>
  //   ),
  //   getItem(<Link to="/addFaq">{firstLetterCaps('Add Faq')}</Link>),
  // ]),

  // getItem(
  //   <Link to="/notic-management">{firstLetterCaps('Notice Management')}</Link>,
  //   <Link to="/notice-management">
  //     <AiOutlineBell size={25} />
  //   </Link>
  // ),

  // getItem(
  //   <Link to="/contact">{firstLetterCaps('Inquiry')}</Link>,
  //   <MdPermContactCalendar size={25} />
  // ),

  // getItem(
  //   <Link to="/subscriber">{firstLetterCaps('Subscriber List')}</Link>,
  //   <Link to="/subscriber">
  //     <MdOutlineUnsubscribe size={25} />
  //   </Link>
  // ),

  getItem('Team Management', <RiTeamLine size={25} />, [
    getItem(<Link to="/viewTeam">{firstLetterCaps('View Team')}</Link>),
    getItem(<Link to="/addTeam">{firstLetterCaps('Add team')}</Link>),
  ]),

  getItem('Project Management', <AiOutlineProject size={25} />, [
    getItem(<Link to="/viewProject">{firstLetterCaps('View Project')}</Link>),
    getItem(<Link to="/addProject">{firstLetterCaps('Add project')}</Link>),
  ]),

  getItem(
    <Link to="/servicePanel">{firstLetterCaps('Service Management')}</Link>,
    <Link to="/servicePanel">
      <AiTwotoneCustomerService size={25} />
    </Link>
  ),

  getItem('Blog', <BsFilePost />, [
    getItem(<Link to="/viewBlog">{firstLetterCaps('View Blog')}</Link>),
    getItem(<Link to="/createBlog">{firstLetterCaps('Create Blog')}</Link>),
  ]),

  getItem(
    <Link to="/media">{firstLetterCaps('Media Management')}</Link>,
    <Link to="/media">
      <MdPermMedia size={25} />
    </Link>
  ),

  getItem(
    <Link to="/siteSetting">{firstLetterCaps('Site Setting')}</Link>,
    <Link to="/siteSetting">
      <FcSettings size={25} />
    </Link>
  ),

  getItem(
    <Link to="/user">{firstLetterCaps('User')}</Link>,
    <Link to="/notice-management">
      <BiUserCircle size={25} />
    </Link>
  ),

  // TODO below:

  getItem('Testimonial Management', <MdReviews size={25} />, [
    getItem(
      <Link to="/viewTestimonial">{firstLetterCaps('View Testimonial')}</Link>
    ),
    getItem(
      <Link to="/addTestimonial">{firstLetterCaps('Add testimonial')}</Link>
    ),
  ]),

  getItem(
    <Link to="/aboutManagement">{firstLetterCaps('About Management')}</Link>,
    <Link to="/aboutManagement">
      <SiAboutdotme size={25} />
    </Link>
  ),

  getItem(
    // 'Client',
    // <FcFaq />,
    <Link to="/client">{firstLetterCaps('Client')}</Link>,
    <Link to="/client">
      <HiUserGroup size={25} />
    </Link>
  ),
];

const CmsPanel = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ height: '100vh', overflowY: 'auto' }}
        width={300}
      >
        <div
          className="logo"
          style={{
            color: 'white',
          }}
        >
          {/* <img src=""
                        alt="random Image" style={{
                            objectFit: "contain"
                        }} /> */}
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          style={{ height: '100vh', overflowY: 'auto', width: '100%' }}
        />
      </Sider>
      <Layout className="site-layout" style={{}}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            width: '100%',
            // border: '1px solid red',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '3rem',
          }}
        >
          <h3
            style={{
              color: 'white',
              // border: '1px solid red',
              // height: '100%',
              text: 'center',
            }}
          >
            VUMI ENGINEERING CONSORTIUM - CMS
          </h3>
        </Header>
        <Content
          style={{
            margin: '0 16px',
            height: '95vh',
            overflowY: 'auto',
          }}
        >
          <PresentWorkingPanel />
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: '80vh',
            }}
          >
            <AdminRoutes />
          </div>
        </Content>
        <Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            maxHeight: '1rem',
            height: '5vh',
          }}
        >
          ©2024 Created by Oplus Labs
        </Footer>
      </Layout>
    </Layout>
  );
};
export default CmsPanel;
