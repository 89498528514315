import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../../auth/ProtectedRoute';
import User from '../../pages/cms/user';

const Dashboard = React.lazy(() =>
  import('../../pages/cms/dashboard/Dashboard')
);
const AddAdvertisement = React.lazy(() =>
  import('../../pages/cms/advertisementPanel/AdvertisementPanel')
);
const ViewAdvertisement = React.lazy(() =>
  import('../../pages/cms/advertisementPanel/ViewAdvertisement')
);
const AddBanner = React.lazy(() =>
  import('../../pages/cms/bannerPanel/BannerPanel')
);
const ViewBanner = React.lazy(() =>
  import('../../pages/cms/bannerPanel/ViewBanner')
);
const Contact = React.lazy(() =>
  import('../../pages/cms/contactPanel/ContactPanel')
);
const AddFaq = React.lazy(() => import('../../pages/cms/faqPanel/FaqPanel'));
const ViewFaq = React.lazy(() => import('../../pages/cms/faqPanel/ViewFaq'));
const FaqCategoryPanel = React.lazy(() =>
  import('../../pages/cms/faqPanel/FaqCategoryPanel')
);
const ClientPanel = React.lazy(() =>
  import('../../pages/cms/Client/ClientPanel')
);
const GalleryManagement = React.lazy(() =>
  import('../../pages/cms/galleryPanel/GalleryPanel')
);
const AddProject = React.lazy(() =>
  import('../../pages/cms/projectPanel/ProjectPanel')
);
const ViewProject = React.lazy(() =>
  import('../../pages/cms/projectPanel/ViewProject')
);
const AddTestimonial = React.lazy(() =>
  import('../../pages/cms/testimonialPanel/TestimonialPanel')
);
const ViewTestimonial = React.lazy(() =>
  import('../../pages/cms/testimonialPanel/ViewTestimonial')
);
const SubscriberManagement = React.lazy(() =>
  import('../../pages/cms/subscriberPanel/SubscriberPanel')
);
const AddTeam = React.lazy(() => import('../../pages/cms/teamPanel/TeamPanel'));
const ViewTeam = React.lazy(() => import('../../pages/cms/teamPanel/ViewTeam'));
const Media = React.lazy(() => import('../../pages/cms/Media/Media'));
const SiteSetting = React.lazy(() =>
  import('../../pages/cms/siteSetting/SiteSetting')
);
const CreateBlog = React.lazy(() =>
  import('../../pages/cms/blogPanel/BlogPanel')
);
const ViewBlog = React.lazy(() => import('../../pages/cms/blogPanel/ViewBlog'));
const PageNotFound = React.lazy(() => import('../../pages/404'));

const ServicePanel = React.lazy(() =>
  import('../../pages/cms/servicePanel/ServicePanel')
);
const AboutManagement = React.lazy(() =>
  import('../../pages/cms/aboutPanel/AboutManagement')
);
const NoticeManagement = React.lazy(() =>
  import('../../pages/cms/noticeManagement')
);

const CmsRoutes = () => {
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate to="/viewProject" />} />
          <Route path="/addAdvertisement" element={<AddAdvertisement />} />
          <Route path="/viewAdvertisement" element={<ViewAdvertisement />} />
          <Route
            path="/editAdvertisement/:advertisementId"
            element={<AddAdvertisement editMode />}
          />

          <Route path="/addProject" element={<AddProject />} />
          <Route path="/viewProject" element={<ViewProject />} />
          <Route
            path="/editProject/:projectId"
            element={<AddProject editMode />}
          />

          <Route path="/addFaq" element={<AddFaq />} />
          <Route path="/viewFaq" element={<ViewFaq />} />
          <Route path="/editFaq/:faqId" element={<AddFaq editMode />} />

          <Route path="/faqCategoryPanel" element={<FaqCategoryPanel />} />
          <Route path="/client" element={<ClientPanel />} />
          <Route
            path="/faqCategoryPanel/:faqCategoryId"
            element={<FaqCategoryPanel editMode />}
          />

          <Route path="/addTeam" element={<AddTeam />} />
          <Route path="/viewTeam" element={<ViewTeam />} />
          <Route path="/editTeam/:teamId" element={<AddTeam editMode />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/subscriber" element={<SubscriberManagement />} />
          <Route path="/page-management" element={<AddAdvertisement />} />

          <Route path="/addBanner" element={<AddBanner />} />
          <Route path="/viewBanner" element={<ViewBanner />} />
          <Route
            path="/editBanner/:bannerId"
            element={<AddBanner editMode />}
          />

          <Route path="/createBlog" element={<CreateBlog />} />
          <Route path="/viewBLog" element={<ViewBlog />} />
          <Route path="/editBlog/:blogId" element={<CreateBlog editMode />} />

          <Route path="/servicePanel" element={<ServicePanel />} />

          <Route path="/galleryManagement" element={<GalleryManagement />} />

          <Route path="/addTestimonial" element={<AddTestimonial />} />
          <Route path="/viewTestimonial" element={<ViewTestimonial />} />
          <Route
            path="/editTestimonial/:testimonialId"
            element={<AddTestimonial editMode />}
          />

          <Route path="/aboutManagement" element={<AboutManagement />} />
          <Route path="/notic-management" element={<NoticeManagement />} />

          <Route path="/siteSetting" element={<SiteSetting />} />
          <Route path="/media" element={<Media />} />
          <Route path="/user" element={<User />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default CmsRoutes;
